/*
import React from 'react';
import { graphql } from 'gatsby';

import Team from '../components/Team'; // Team
import Members from '../components/Members'; //Members
import SEO from '../components/SEO';

const QuienesSomosPage = ({ data }) => {
  const team = data.team.nodes[0];
  const members = data.members.nodes;

  return (
    <>
      <SEO title="El equipo" />
      <Team team={team} />
      <Members members={members} />
    </>
  );
};

export const query = graphql`
  query {
    team: allSanityTeam {
      nodes {
        title {
          es
        }
        description {
          es
        }
      }
    }
    members: allSanityMember {
      nodes {
        icon {
          asset {
            url
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title {
          es
        }
        description {
          es
        }
      }
    }
  }
`;

export default QuienesSomosPage;
*/
import React from "react";

const QuienesSomosPage = () => {
  return (
    
    <>
      {/* <SEO title="Nosotros" /> */}
    </>
  );
};

export default QuienesSomosPage;
